module.exports = [{
      plugin: require('../../../.yarn/$$virtual/gatsby-plugin-offline-virtual-d185241ce7/0/cache/gatsby-plugin-offline-npm-3.8.0-16c35b232b-31aa9bf3c9.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/$$virtual/gatsby-plugin-manifest-virtual-de7f67eea4/0/cache/gatsby-plugin-manifest-npm-2.10.0-7d7c41444a-8efe6f2c03.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../../../.yarn/$$virtual/gatsby-plugin-mdx-virtual-0e9314c225/0/cache/gatsby-plugin-mdx-npm-1.8.0-11d9284729-45ed9223e9.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
